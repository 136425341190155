@import "../Shared/Partials/variables";

.error-page {
    padding: 130px 0;
    text-align: center;
    .sf-bigsize {
        color: $color-secondary;
        padding-bottom: 30px;
    }
    h3 {
        text-transform: uppercase;
    }
    h6 {
        padding-bottom: 20px;
    }
    .btn {
        display: block;
        height: 60px;
        line-height: 60px;
        margin: auto;
    }
    // &>* {
    //     margin-bottom: 20px;
    // }
}