$color-primary: #F58220;
$color-secondary: #5D2E86;

$color-black: #0c0c0c;
$color-27: #272727;
$color-48: #484848;
$color-7E: #7E7E7E;
$color-99: #999999;
$color-C2: #C2C2C2;
$color-E6: #E6E6E6;
$color-white: #FFFFFF;

$color-call-action: #FCEAFF;
$color-blog-inspi: #F2EDF8;
$color-blog-cash: #FFE0B2;