$font-7: 7px;
$font-8: 8px;
$font-9: 9px;
$font-10: 10px;
$font-11: 11px;
$font-12: 12px;
$font-13: 13px;
$font-14: 14px;
$font-15: 15px;
$font-16: 16px;
$font-17: 17px;
$font-18: 18px;
$font-19: 19px;
$font-20: 20px;
$font-21: 21px;
$font-22: 22px;
$font-23: 23px;
$font-24: 24px;
$font-25: 25px;
$font-26: 26px;
$font-27: 27px;
$font-28: 28px;
$font-29: 29px;
$font-30: 30px;

$font-35: 35px;
$font-40: 40px;
$font-45: 45px;
$font-50: 50px;
$font-70: 70px;
$font-80: 80px;
